<template>
<v-dialog v-model="dialog.calendario_resumen" persistent max-width="450" :transition="DialogAnimation" class="rounded-lg" scrollable :overlay-opacity="TemaDark?'.75':'.25'" overlay-color="black">
    <v-card class="rounded-lg" style="scroll-behavior: smooth;">
        <v-card-title class="pa-0 modal-title" v-if="!skeleton">
            <v-toolbar flat class="rounded-t-lg" height="56">
                <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                    <v-list-item class="pl-0 rounded-t-lg">
                        <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8" />
                        <v-list-item-content class="py-0">
                            <v-list-item-title class="headline pb-0 mb-0">
                                <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;" :class="TemaDark?'grey--text text--lighten-4':'grey--text text--darken-1'">Resumen Servicios</v-toolbar-title>
                            </v-list-item-title>
                            <v-list-item-subtitle class="font-weight-medium">
                                <span class="text-capitalize">{{datos.mes_nombre}}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-toolbar-title>
                <!--<v-spacer></v-spacer>
                <v-btn icon @click="CloseDialog('calendario_resumen')" class="mr-0">
                    <v-icon size="20">mdi-close</v-icon>
                </v-btn>-->
            </v-toolbar>
        </v-card-title>
        <v-card flat max-height="70vh" min-height="270px" class="scroll-card">
            <v-container class="pb-0 py-0">
                <v-row v-if="skeleton" class="py-0">
                    <v-col cols="12">
                        <v-skeleton-loader type="list-item-three-line,list-item-avatar-two-line,list-item-avatar-two-line" />
                    </v-col>
                </v-row>
                <v-row class="px-0" v-if="!skeleton">
                    <v-col cols="12" class="pa-0" v-if="datos.servicios">
                        <v-list-item :key="index" class="rounded-lg " v-for="(fecha, index) in datos.servicios" :class="fecha.fecha > FechaHoy?'resumen-disabled':''">
                            <v-list-item-content>
                                <v-list-item :key="index" class="px-1 rounded-lg" v-for="(servicio, index) in fecha.servicios">
                                    <v-list-item-avatar class="my-0 mr-0" height="45" style="display: block;">
                                        <span class=" text-body-2 font-weight-medium turno-title text-capitalize" :class="TemaDark?'grey--text text--lighten-4':'primary--text'" v-if="!index">{{FormatFecha(fecha.fecha).nombre}}</span>
                                        <v-avatar size="28" v-if="!index" style="margin-top: -4px;" :color="TemaDark?'primary':'secondary'" >
                                            <span class=" text-body-2 font-weight-medium turno-title" :class="TemaDark?'white--text':'primary--text'" >{{FormatFecha(fecha.fecha).numero}} </span>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 pl-1">
                                        <v-container class="pa-0">
                                            <v-row class="ma-0">
                                                <v-col cols="8" class="py-0 pl-0">
                                                    <span class="text-subtitle-2 font-weight-medium" :class="TemaDark?'grey--text text--lighten-4':'grey--text text--darken-1'">
                                                        <v-icon class="mb-1 mr-1" size="15" :class="TemaDark?'grey--text text--lighten-4':'grey--text text--darken-1'">{{ServicioIcono(servicio.servicio, servicio.dir)}}</v-icon>{{servicio.servicio}}
                                                        <span class=" text-subtitle-2 grey--text font-weight-medium" v-if="servicio.equipo_1">
                                                            (XT {{servicio.equipo_1}}<span class=" text-subtitle-2 grey--text font-weight-medium" v-if="servicio.equipo_2">-{{servicio.equipo_2}}</span>)
                                                        </span>
                                                    </span>
                                                    <v-list-item-subtitle class="font-weight-medium grey--text ml-1">{{FormatRecorrido(servicio.dir)}}</v-list-item-subtitle>
                                                </v-col>
                                                <v-col cols="4 d-flex justify-end py-0 px-0 rounded-lg">
                                                    <v-card width="100%" flat>
                                                        <v-row class="ma-0">
                                                            <v-col cols="6" class="px-0 pb-1 pt-0 text-center">
                                                                <span class="text-subtitle-2 grey--text">
                                                                    {{servicio.servicio_ini}}
                                                                </span>
                                                                <p class="text-subtitle-2 grey--text text-center mb-0" style="line-height: 1.1;">
                                                                    {{servicio.inicio?`(${servicio.inicio > 0? `+${servicio.inicio}`: `${servicio.inicio}`})`:'-'}}
                                                                </p>
                                                            </v-col>
                                                            <v-divider vertical class="my-1"></v-divider>
                                                            <v-col cols="6" class="px-0 pb-1 pt-0 text-center">
                                                                <div>
                                                                    <span class="text-subtitle-2 grey--text">
                                                                        {{servicio.servicio_ter}}
                                                                    </span>
                                                                    <p class="text-subtitle-2 grey--text text-center mb-0" style="line-height: 1.1;">
                                                                        {{servicio.termino?`(${servicio.termino > 0? `+${servicio.termino}`: `${servicio.termino}`})`:'-'}}
                                                                    </p>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12" class="py-0 px-0">
                                                    <v-card flat>
                                                        <p class="text-subtitle-2 grey--text font-weight-medium mb-0 ml-0" v-if="servicio.obs">
                                                            <v-icon color="primary" size="15" class="mb-0" style="vertical-align: baseline;">mdi-alert-circle-outline</v-icon>
                                                            Obs: {{servicio.obs}}</p>
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="12" class="py-0 px-0" v-for="(fu,index) in servicio.fu" :key="index">
                                                    <v-card flat>
                                                        <p class="text-subtitle-2 red--text font-weight-medium mb-0 ml-0">
                                                            <v-icon color="red" size="15" class="mb-0" style="vertical-align: baseline;">mdi-alert-circle-outline</v-icon>
                                                            FU: {{FuTipo(fu.fu_tipo)}} <span v-if="fu.fu_obs">({{fu.fu_obs}})</span>
                                                        </p>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" class="pa-0" v-else>
                        <v-card flat height="40vh" class="justify-center align-center d-flex flex-column">
                            <v-icon size="50" color="grey lighten-1">
                                mdi-playlist-remove
                            </v-icon>
                            <span class="trenos-title-toolbar grey--text text--lighten-1">Sin servicios registrados</span>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="CloseDialog('calendario_resumen')">
                Cerrar
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import dayjs from "dayjs";
export default {
    name: 'CalendarioResumen',
    props : ['datos'],
    data() {
        return {
            skeleton: false
        }
    },
    computed:{
        ...mapState(['dialog','sistema']),
        FechaHoy() {
            return dayjs().format('YYYY-MM-DD')
        },
        DialogResumen(){
            return this.dialog.calendario_resumen
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods:{
        ...mapMutations(['CloseDialog']),
        FormatFecha(fecha) {
            let dia = {
                nombre: dayjs(fecha).format('ddd').replace(".", ""),
                numero: dayjs(fecha).format('DD')
            }
            return dia
        },
        ServicioIcono(servicio, direccion) {
            let icono = null
            if (servicio > 0) {
                switch (direccion) {
                    case 'pu-li':
                        icono = 'mdi-arrow-up'
                        break;
                    case 'li-pu':
                        icono = 'mdi-arrow-down'
                        break;
                    case 'pu-sa':
                        icono = 'mdi-arrow-up'
                        break;
                    case 'sa-pu':
                        icono = 'mdi-arrow-down'
                        break;
                    case 'pu-eb':
                        icono = 'mdi-arrow-up'
                        break;
                    case 'eb-pu':
                        icono = 'mdi-arrow-down'
                        break;
                    case 'sa-mi':
                        icono = 'mdi-arrow-down'
                        break;
                    case 'po-sa':
                        icono = '   mdi-arrow-up'
                        break;
                }
            } else {
                direccion == 'descanso' ? icono = 'mdi-coffee-outline' : icono = 'mdi-checkbox-blank-circle-outline'
            }
            return icono
        },
        FormatRecorrido(recorrido) {
            let nombre_recorrido
            switch (recorrido) {
                case 'pu-li':
                    nombre_recorrido = 'Puerto - Limache'
                    break;
                case 'li-pu':
                    nombre_recorrido = 'Limache - Puerto'
                    break;
                case 'pu-sa':
                    nombre_recorrido = 'Puerto - S.Aldea'
                    break;
                case 'sa-pu':
                    nombre_recorrido = 'S.Aldea - Puerto'
                    break;
                case 'eb-pu':
                    nombre_recorrido = 'El Belloto - Puerto'
                    break;
                case 'pu-eb':
                    nombre_recorrido = 'Puerto - El Belloto'
                    break;
                case 'sa-mi':
                    nombre_recorrido = 'S.Aldea - Miramar'
                    break;
                case 'po-sa':
                    nombre_recorrido = 'Portales - S.Aldea'
                    break;
                default:
                    break;
            }
            return nombre_recorrido
        },
        FuTipo(tipo) {
            let tipo_nombre
            switch (tipo) {
                case 0:
                    tipo_nombre = 'ATP'
                    break;
                case 1:
                    tipo_nombre = 'VA'
                    break;
                case 2:
                    tipo_nombre = 'Manipulador'
                    break;
                case 3:
                    tipo_nombre = 'TP'
                    break;
                case 4:
                    tipo_nombre = 'Hongo'
                    break;
                case 5:
                    tipo_nombre = 'Puertas'
                    break;
            }
            return tipo_nombre
        }
    },
    watch:{
        DialogResumen: function (val) {
            if(val) this.skeleton = true
        },
        datos:function (val) {
            if(val.mes_nombre){
                this.skeleton = false
            }else{
                this.skeleton = true
            }
        }
    }
}
</script>

<style scoped>

.resumen-disabled{
    opacity: .5 !important;
}
.scroll-card{
    will-change: transform !important;
    scroll-behavior: smooth;
    overflow: auto
}
</style>